// JEREMIAS <- FIBEL SKIN <- BLUMFELD SKIN

@import url('https://fonts.cdnfonts.com/css/wix-madefor-text');

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  //background-color: aqua;
}

.g8-page-content,
body.ticket-listing-page {
  background: linear-gradient(rgba($black, 0), rgba($black, 1)), url('./assets/images/bg2.jpg') center center;
  background-repeat: repeat;
  background-size: 1200px !important;
}

.g8-logo {
  filter: invert(1);
}

.card.bg-light {
  background-color: transparent !important;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
}

.container {
  // padding-left: 50px;
  // padding-right: 50px;
}

.invisible {
  display: none;
}

// NAV
#header {
  @extend .sticky-top;
  transition: transform 0.15s ease-out;
  border-bottom: 0.1rem solid rgba($basetext, 0.08);
  background-color: $bgcolor !important;
  z-index: 2;

  .navbar {
    @extend .container;

    // max-width: 1000px;

    //padding-top: 1.5rem !important;
    //padding-bottom: 1.5rem !important;
    @include media-breakpoint-down(sm) {
      //padding-top: 0.6rem !important;
      //padding-bottom: 0.6rem !important;
    }
  }

  .bg-light {
    background-color: $bgcolor !important;
  }
}
body[data-scrolled] {
  #header {
    transform: translateY(-100%);
  }
}
body[data-scrollingup],
body[data-menuopen][data-scrolled] {
  #header {
    transform: translateY(0%);
  }
}

#header .navbar {
  .navbar-brand {
    padding-left: 0;
    img {
      //max-width: 130px;
      padding-left: 0;
      height: 3.5rem;
      max-height: 3.5rem;

      &:hover,
      &:focus {
        // filter: opacity(0.66);
      }
    }
    @include media-breakpoint-down(sm) {
      img {
        // max-width: 75px;
        height: 2rem;
        max-height: 2rem;
      }
      padding-left: 5px;
      // height: 3.5rem;
      // max-height: 3.5rem;
    }
  }
}

#header {
  // big screens
  .main-menu {
    // hide the main menu hamburger
    display: none !important;
  }
  .navbar .secondary-menu {
    justify-content: end;
    padding-left: 1.5rem;
    .nav-item.last a {
      padding-right: 0;
      &:hover {
        text-decoration: none !important;
      }
    }

    .navbar-nav {
      color: $basetext;
      font-weight: 700;
      font-size: large;
    }
    .menu {
      margin-right: 1rem;
    }
  }

  // small screens
  @include media-breakpoint-down(sm) {
    .navbar-brand-container {
      flex-grow: 1;
    }

    .main-menu {
      display: flex !important;
    }

    .navbar .secondary-menu {
      justify-content: end !important;
      padding-left: 0;

      .menu {
        // hide category links
        display: none;
      }
      .navbar-nav {
        color: $primary;
        font-weight: 500;
        font-size: large;
      }
    }
    .flex-nowrap {
      justify-content: space-between;
    }
  }
}

// body[data-scrolled] {
//   #header:has(#cart-menu.open),
//   #header:has(#main-menu.open) {
//     opacity: 1 !important;
//     transform: translateY(0px);
//   }
// }

.navbar {
  .hamburger {
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    height: 1.25px;
  }

  .hamburger:hover {
    transform: scale(1.1) !important;
    transition: transform 0.15s ease, opacity 0.15s ease;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  //flex-grow: 1;
  //margin-left: 20px;
  //min-width: 70px !important;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

#header .navbar .navbar-collapse .navbar-nav,
#header .navbar .secondary-menu .navbar-nav {
  .nav-item a {
    font-size: $font-size-base;
    color: rgba($basetext, 0.75) !important;

    &:hover,
    &.active {
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      color: $secondary !important;
    }
  }
}

.offcanvas-collapse {
  background-color: rgba($bgcolor, 0.95);
  top: 84px;
  box-shadow: none;
  @include media-breakpoint-down(sm) {
    top: 61px;
  }
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    //background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: rgba(18, 18, 18, 0.75) !important;
    }
    a.active,
    a:hover {
      text-decoration: underline;
      text-underline-offset: 0.3rem;
      color: rgb(18, 18, 18) !important;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    //text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
  .nav-item:hover {
    background-color: transparent;
  }
}

.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// MAIN MENU
#main-menu {
  min-width: 90vw;
  height: 100vh;
  padding-top: 3rem;
  border-right: 0.1rem solid rgba($basetext, 0.08);
}

// OC CART
.offcanvas-collapse-right {
  width: min(90vw, 480px);
  height: 100vh !important;
  padding-top: 1rem;
  margin-left: 2rem;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  border-left: 0.1rem solid rgba($basetext, 0.08);
  h5 {
    font-size: $h5-font-size;
    // text-transform: uppercase;
    color: $basetext !important;
  }
}

// MAKI
.masonry.row {
  //margin: 0;
}

.maki.card {
  overflow: hidden;

  .card-title {
    display: block;
    text-align: center;
    padding-bottom: 2rem;

    .title {
      font-weight: 700 !important;
      //padding-bottom: 0.8rem;
      //font-size: 13px;
      display: block;
      width: 100%;
    }

    .sub-title {
      font-size: $font-size-sm;
      display: block;
      width: 100%;
      color: $gray-600;
    }

    .price {
      font-weight: bold;
      display: inline-block;
    }

    .former-price {
      text-decoration: line-through;
      display: inline-block;
      //font-size: 13px;
      //line-height: 1.6rem;
      color: $secondary !important;
    }
  }

  .maki-img-container {
    overflow: hidden;
    background-color: #fff;
    position: relative;
  }

  .card-header {
    color: $gray-900;
  }

  // // trying to control row placement
  // .card-title {
  //   display: grid;
  //   grid-template-columns: auto 1fr;
  //   grid-template-rows: auto auto;
  //   .date-container {
  //     display: none;
  //   }
  //   .title {
  //     grid-column-start: 1;
  //     grid-column-end: 3;
  //   }
  // }
  // TAGS

  .tag {
    background-color: $info;
    position: absolute;
    bottom: 10.5rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
  }

  &:hover {
    .title {
      //text-decoration: underline;
    }
    .maki-img {
      transform: scale(1.03);
      transition: transform 0.5s ease;
      background-color: $white;
    }
    .maki-img-container {
      background-color: $white;
      overflow: hidden;
    }
  }
}

// PDP

.detail-page {
  .super-title {
    font-size: $font-size-sm;
    color: $gray-600 !important;
    opacity: 1;
  }

  .sub-title {
    color: $gray-600;
  }

  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
    span {
      font-size: 40px;
      line-height: 52px;
      font-weight: 700;
    }
  }
  .variant-chooser {
    text-align: left !important;
  }
  small.former-price {
    text-decoration: line-through;
    color: $secondary !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 10px;
  }
  .price {
    display: block;
    text-align: left !important;
    font-size: 18px;
    line-height: 27px;
    @extend .mt-3;
  }

  div[itemprop='description'] {
    p {
      //color: rgba(18, 18, 18, 0.75) !important;
      // font-size: 16px !important;
      // font-style: normal !important;
      // font-weight: 500 !important;
      // letter-spacing: 0.6px !important;
      // line-height: 28.8px !important;
    }
  }

  button.add-product {
    align-items: center;
    appearance: none;
    background-color: rgb(241, 239, 237);
    border: $basetext 1px solid;
    box-sizing: border-box;
    // display: flex;
    // justify-content: center;
    letter-spacing: 1px;
    // margin-bottom: 10px;
    // padding-bottom: 0px;
    // padding-left: 30px;
    // padding-right: 30px;
    // padding-top: 0px;
    text-transform: initial;
  }

  .carousel-indicators {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;

    button {
      width: 49%;
      border: 0px solid transparent;

      .product-thumb {
        height: 100% !important;
        width: 100% !important;
        transform: translate(-5px, -5px);
        margin: 0;
      }
    }
  }

  .carousel-inner {
    background-color: #fff;
  }

  // .variant-chooser-quantity {
  //   border: rgb(18, 18, 18) 1px solid;

  //   button {
  //     align-items: center;
  //     background-color: rgba(0, 0, 0, 0);
  //     color: rgb(18, 18, 18);
  //     display: flex;
  //     font-size: 18px;
  //     font-weight: 400;
  //     justify-content: center;
  //     margin-left: 1px;
  //     padding-bottom: 0px;
  //     padding-left: 0px;
  //     padding-right: 0px;
  //     padding-top: 0px;
  //   }
  // }

  @include media-breakpoint-down(md) {
    header {
      text-align: left !important;

      .super-title.text-muted,
      .product-header-title {
        text-align: left !important;
      }
      .product-header-title {
        font-weight: 700;
      }
    }
    .price,
    h2.product-title {
      text-align: left !important;
    }
    .price {
      font-size: 16px;
      font-weight: 700;
    }
    .variant-chooser-quantity,
    .variant-chooser-cta {
      flex: 1 1 100% !important;
    }
  }
}

.btn.btn-primary {
  appearance: none;
  background-color: transparent !important;
  border: $basetext 1px solid;
  color: $basetext;
  font-weight: 700;
  letter-spacing: 1px;
  &:hover {
    //border: rgb(18, 18, 18) 2px solid;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $secondary;
  &:hover,
  &:focus {
    color: lighten($secondary, 0.2);
  }
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: opacity(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: opacity(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $black;
  color: rgba($bgcolor, 0.95);
  a {
    color: rgba($bgcolor, 0.95);
  }
  min-height: unset;
  //border-top: 0.1rem solid rgba($basetext, 0.08);
  p,
  li {
    // opacity: 0.8;
  }
}

#product-listing {
  padding-top: 3rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// header image
.header-image {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: hidden;
  background-color: $gray-900;
  z-index: -1;

  // @include media-breakpoint-down(sm) {
  //   height: 390px;
  // }

  // img {
  //   width: 100%;
  // }
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    //background: url('./assets/images/bg.jpg') no-repeat center center;
    //background-size: cover;
    //filter: blur(1.5rem);
    //opacity: 0.8;
    background-position: center center;
  }
}

// various
.simple-cart .icon svg {
  // height: 20px !important;

  &:hover {
    transform: scale(1.07) !important;
  }
}
.navbar-light .navbar-nav .nav-link svg.icon {
  color: $basetext;
}

i {
  font-size: 1.2rem;
}

span.item-count {
  background-color: $secondary;
  transform: scale(0.7) translateX(63px);
  color: #fff !important;
}

span.expiration {
  //display: none;
  i.fa-clock {
    display: none;
  }
}

// versandart wählen
.checkout {
  .shipping-method-list,
  .payment-method-list {
    label {
      background: none !important;
      color: rgba($basetext, 0.5) !important;
    }
    label.active {
      color: $basetext !important;
      background-color: rgba($secondary, 0.3) !important;
    }
  }
}

// remove borders on tt-listing
.tt-listing {
  th,
  td {
    border-bottom: none !important;
  }
}

// maybe also on modals
.modal {
  .modal-header,
  .sticky-bottom {
  }
}

// TICKETS

#ticket-listing,
.ticket-listing {
  .ticket-header-image {
    background-image: url('./assets/images/ticket-header-image.jpg');
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 0;
    height: 50vh;
    // height: calc(100vh - 72px);
    // @include media-breakpoint-up(md) {
    //   height: calc(100vh - 60px);
    // }
  }

  .list-page {
    padding-top: 4vh !important;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }

  .nigiri.card {
    background-color: transparent;
    border: 0.1rem solid rgba($white, 0.08);
    border-radius: 0;

    //border-bottom: 1px solid rgba($white, 0.25);
    .nigiri-content {
      padding: 1.25rem;
      margin-top: 0.2rem;
      color: $white;
      @include media-breakpoint-up(md) {
        align-items: center;
      }
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h3-font-size;
      color: $white;
      align-items: center !important;
      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 3rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .badge {
      z-index: 2;
      font-size: 1.2em;
    }
    .sold-out-status_rescheduled {
      background-color: #ffa500;
    }
    .title {
      font-size: $font-size-base * 1.2;
      margin-bottom: 0;
      color: $secondary;
      font-family: $font-family-sans-serif !important;
    }
    .subtitle {
      font-size: $font-size-sm;
      margin-bottom: 0;
      color: $gray-200;
      font-weight: normal;
      text-transform: none;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
        margin-bottom: 0.5rem;
      }
    }
    .location {
      font-family: 'Andada Pro', $font-family-sans-serif !important;
      font-weight: 600 !important;
      font-size: $h3-font-size !important;
      text-transform: none;
      color: $white;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
    .btn.btn-primary {
      border: 1px solid $white;
      color: $white;
    }
    &:hover {
      background-color: rgba($pcred, 0.5);
    }
  }
}

.ticket-detail-page {
  .blurry-background-image {
    height: auto;
    background-color: transparent;
  }

  .blurry-background-image:before {
    //filter: blur(1.5rem);
    filter: none;
    opacity: 1;
    background-position: center center;
    background: linear-gradient(rgba($black, 0), rgba($black, 1)), url('./assets/images/bg2.jpg') !important;
    background-size: 1200px !important;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    //display: none !important;
  }

  .article-title {
    padding-left: 0;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    border-bottom: 0.1rem solid rgba($basetext, 0.08);

    .super-title {
      font-size: 0.7rem;
      opacity: 1;
    }
  }

  .product-info-bar {
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem solid rgba($basetext, 0.08);
    box-shadow: none;
    i.far {
      color: $secondary !important;
    }
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }
  .variant-listing {
    .visible,
    .invisible {
      display: none !important;
    }
  }
}

// make category detail look like product listing
body.category-detail-page {
  article.category-detail {
    margin-bottom: 0 !important;
    section.container {
      padding-top: 0 !important;
    }
  }
}

.checkout .when-valid.is-valid,
form .when-valid.is-valid,
.checkout .when-valid,
form .when-valid,
.shoppingcart-finisher {
  background-color: rgba($secondary, 0.9);
  .btn {
    filter: invert(1);
  }
}

// HIGHLIGHTS

.list-page-highlights-wrapper {
  overflow-x: hidden;
}

.list-page-highlights {
  background-color: transparent;
  position: relative;
  z-index: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  h1 {
    //transform: rotate(-1.5deg);
  }
  .maki.card {
    box-shadow: $box-shadow-xl;
    .card-body {
      background-color: #fff;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0;
    }
  }
  #product-listing {
    padding-top: 2rem;
    @include media-breakpoint-up(md) {
      //transform: rotate(-1.5deg);
    }
  }
  .container-fluid {
    @include media-breakpoint-up(md) {
      max-width: 90%;
    }
  }
}

.list-page-highlights::before {
  background-color: rgba($secondary, 1);
  position: absolute;
  content: '';
  display: block;
  top: 0;
  bottom: 0;
  width: 200%;
  left: -50%;
  z-index: -1;
  //transform: rotate(-1.5deg);
  box-shadow: $box-shadow-xl;
}
.list-page-tickets {
  margin-top: 6rem;
}

// HEADER IMAGE

// header image
.header-image {
  background-image: url('./assets/images/header.gif');
  background-position: top;
  background-size: contain;
  margin: 0;
  padding: 0;
  height: 75vh;

  @include media-breakpoint-down(sm) {
    height: 300px;
  }

  // img {
  //   width: 100%;
  // }
}
